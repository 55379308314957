.container {
  background-color: transparent;
  position: relative;
  perspective: 1000px;

  &.flipped, &.flipped .card {
    transform: rotateY(0.5turn);
  }

  .card {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    transition: 0.5s ease-in-out;
    transform-style: preserve-3d;

    .back, .front {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      border-radius: 10px;
    }

    .back {
      background-color: #c4e8f2;
      box-shadow: 0 0 4px 1px rgba(9,116,216,0.9), inset 0 0 0 10px white;
    }

    .front {
      transform: rotateY(0.5turn);
      font-size: 12vmin;
      background-color: #f6f8fa;
      box-shadow: 0 0 4px 1px rgba(9,116,216,0.9);
    }
  }
}