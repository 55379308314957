@use 'src/styles/variables.scss';

.cardsBoard {
  height: calc(100% - variables.$header-width);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

